import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { animateScroll as scroll } from "react-scroll";

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import { xmas, box } from "../Xmas";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle
                xmas={
                  new Date() >= new Date(xmas) && new Date() <= new Date(box)
                    ? "true"
                    : undefined
                }>
                COMPANY
              </FooterLinkTitle>
              <FooterLink
                to="/discover"
                onClick={toggleHome}
                smooth="true"
                offset={-70}
                duration={400}
                xmas={
                  new Date() >= new Date(xmas) && new Date() <= new Date(box)
                    ? "true"
                    : undefined
                }>
                About Us
              </FooterLink>
              <FooterLink
                to="/professionals"
                onClick={toggleHome}
                smooth="true"
                offset={-70}
                duration={400}
                xmas={
                  new Date() >= new Date(xmas) && new Date() <= new Date(box)
                    ? "true"
                    : undefined
                }>
                Professionals
              </FooterLink>
              <FooterLink
                to="/support"
                onClick={toggleHome}
                smooth="true"
                offset={-70}
                duration={400}
                xmas={
                  new Date() >= new Date(xmas) && new Date() <= new Date(box)
                    ? "true"
                    : undefined
                }>
                Client Support
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>

        <SocialMedia>
          <SocialMediaWrap>
            <SocialIcons>
              <SocialIconLink
                href="//www.linkedin.com/in/wofh2015/"
                target="_blank"
                aria-label="LinkedIn"
                xmas={
                  new Date() >= new Date(xmas) && new Date() <= new Date(box)
                    ? "true"
                    : undefined
                }>
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink
                href="//www.twitter.com/woh_ltd"
                target="_blank"
                aria-label="Twitter"
                xmas={
                  new Date() >= new Date(xmas) && new Date() <= new Date(box)
                    ? "true"
                    : undefined
                }>
                <FaXTwitter />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
        <WebsiteRights
          xmas={
            new Date() >= new Date(xmas) && new Date() <= new Date(box)
              ? "true"
              : undefined
          }>
          Copyright © Wheel Of Health Limited {new Date().getFullYear()}. Wheel
          of Health is the trading name of Wheel of Health Limited, registered
          in England and Wales under Company number{" "}
          <a
            href="https://beta.companieshouse.gov.uk/company/07096085"
            target="_blank"
            rel="noreferrer">
            7096085.
          </a>
          . All Rights Reserved.
        </WebsiteRights>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
