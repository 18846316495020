import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import elfImage from "../../images/elfie.png"; // Path to the elf image

// Animations
const swingAnimation = keyframes`
  0%, 100% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
`;

// Styled Components
const ElfieWrapper = styled.div`
  position: fixed;
  ${({ position }) =>
    position === "top"
      ? `top: -20px;`
      : position === "bottom"
      ? `bottom: -20px;`
      : `top: 50%; transform: translateY(-50%);`}
  ${({ alignment }) =>
    alignment === "left" ? `left: -20px;` : `right: -20px;`}
  z-index: 9999;
  pointer-events: auto;
  animation: ${swingAnimation} 2s infinite ease-in-out;
  cursor: pointer;
`;

const ElfieImage = styled.img`
  width: 100px; /* Adjust size */
  transform: ${({ alignment }) =>
    alignment === "left"
      ? "scaleX(-1)"
      : "none"}; /* Flip horizontally for left side */
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 14px;
  padding: 20px;
  border-radius: 10px;
  z-index: 10000;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 768px) {
    width: 90%; /* Mobile-friendly width */
  }
`;

const PopupButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: var(--color-darkpink);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: var(--color-lightpink);
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;

const Elfie = () => {
  const [position, setPosition] = useState("top");
  const [alignment, setAlignment] = useState("left");
  const [showPopup, setShowPopup] = useState(false);
  const [originalStyles, setOriginalStyles] = useState(new Map());

  useEffect(() => {
    // Randomly determine the position and alignment
    const randomPosition = ["top", "bottom", "center"][
      Math.floor(Math.random() * 3)
    ];
    const randomAlignment = ["left", "right"][Math.floor(Math.random() * 2)];
    setPosition(randomPosition);
    setAlignment(randomAlignment);
  }, []);

  const messUpWebsite = () => {
    const elements = document.querySelectorAll("*");
    const savedStyles = new Map();

    elements.forEach((element) => {
      // Save original styles
      savedStyles.set(element, {
        color: element.style.color,
        fontSize: element.style.fontSize,
      });

      // Apply random styles
      const randomColor = `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)})`;
      const randomFontSize = `${Math.floor(Math.random() * 20) + 12}px`;
      element.style.color = randomColor;
      element.style.fontSize = randomFontSize;
    });

    // Save original styles for restoration
    setOriginalStyles(savedStyles);

    // Show popup after 2 seconds
    setTimeout(() => setShowPopup(true), 2000);
  };

  const resetStyles = () => {
    originalStyles.forEach((style, element) => {
      element.style.color = style.color || "";
      element.style.fontSize = style.fontSize || "";
    });
    setShowPopup(false);
  };

  return (
    <>
      <ElfieWrapper
        position={position}
        alignment={alignment}
        onClick={messUpWebsite} // Trigger action on click
      >
        <ElfieImage src={elfImage} alt="Elfie" alignment={alignment} />
      </ElfieWrapper>
      {showPopup && (
        <Popup>
          <p>Elfie has caused some mischief! Try to keep him under control!</p>
          <PopupButton onClick={resetStyles}>Got it!</PopupButton>
        </Popup>
      )}
    </>
  );
};

export default Elfie;
