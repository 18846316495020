import React from "react";
import styled from "styled-components";
import LW from "../../images/team/lw.jpg";
import SW from "../../images/team/sw.jpg";
import SR from "../../images/team/sr.jpg";
import CA from "../../images/team/ca.jpg";
import DB from "../../images/team/db.jpg";
import LL from "../../images/team/ll.jpg";
import JT from "../../images/team/jt.jpg";
import LA from "../../images/team/la.jpg";
import EW from "../../images/team/ew.jpg";
import AJ from "../../images/team/aj.jpg";
import VT from "../../images/team/vt.jpg";
import JM from "../../images/team/jm.jpg";
import LO from "../../images/team/lo.jpg";
import EM from "../../images/team/em.jpg";
import CE from "../../images/team/ce.jpg";
import XS from "../../images/team/xs.jpg";
import VO from "../../images/team/vo.jpg";
import LC from "../../images/team/lc.jpg";
import LS from "../../images/team/ls.jpg";
import RS from "../../images/team/rs.jpg";
import SCS from "../../images/team/scs.jpg";
import LOF from "../../images/team/lof.jpg";
import JRW from "../../images/team/jrw.jpg";
import TC from "../../images/team/tc.jpg";

// Styled Components
const ClientContainer = styled.div`
  max-height: auto;
  margin: auto auto;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--color-black);
`;

const ClientWrapper = styled.div`
  max-width: auto;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (min-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 40px;
  }
`;

const ClientCard = styled.div`
  background: var(--color-black);
  border: 1px solid var(--color-darkpink);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  height: 320px;
  width: 300px;
  padding: 30px;
  z-index: 2;

  &:hover {
    background: var(--color-lightergrey);
    color: var(--color-darkpink);
    transform: scale(1.02);
    box-shadow: 0 0 42px 0 rgba(236, 64, 122, 0.8);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

const StyledImage = styled.div`
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

const ClientTopLine = styled.p`
  color: var(--color-darkpink);
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const ClientHeader = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: var(--color-lightergrey);

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const ClientSubHeading = styled.p`
  width: 75%;
  padding: 6px;
  border-radius: 25px;
  margin: 40px 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-lightergrey);
  background-color: var(--color-darkpink);
  border: 1px solid var(--color-darkpink);

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ClientH2 = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const ClientP = styled.p`
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
`;

// Team Data Arrays
const seniorLeadership = [
  {
    name: "Lucy Weech",
    role: "Clinical Director",
    extra: "CV available",
    img: LW,
  },
  {
    name: "Simon Weech",
    role: "Director",
    extra: "Digital Support",
    img: SW,
  },
  {
    name: "Sam Richardson",
    role: "Administration Manager & PA to Lucy Weech",
    extra: "CV available",
    img: SR,
  },
];

const teamLeaders = [
  {
    name: "Claire Ashton",
    role: "Occupational Therapist",
    extra: "CV available",
    img: CA,
  },
  {
    name: "Deborah Bowditch",
    role: "Occupational Therapist",
    extra: "CV available",
    img: DB,
  },
  {
    name: "Louise Lloyd",
    role: "Occupational Therapist",
    extra: "CV available",
    img: LL,
  },
  {
    name: "Julian Throssell",
    role: "Occupational Therapist",
    extra: "CV available",
    img: JT,
  },
];

const seniorAssociates = [
  {
    name: "Lisa Alexander",
    role: "Occupational Therapist",
    extra: "CV available",
    img: LA,
  },
  {
    name: "Emma Wakeling",
    role: "Occupational Therapist",
    extra: "CV available",
    img: EW,
  },
  {
    name: "Amanda Jackson",
    role: "Occupational Therapist",
    extra: "CV available",
    img: AJ,
  },
  {
    name: "Vicky Thompson",
    role: "Occupational Therapist",
    extra: "CV available",
    img: VT,
  },
  {
    name: "Jo McConnell",
    role: "Occupational Therapist",
    extra: "CV available",
    img: JM,
  },
  {
    name: "Lauren Osborne",
    role: "Occupational Therapist",
    extra: "CV available",
    img: LO,
  },
  {
    name: "Lizzy McWilliams",
    role: "Physiotherapist",
    extra: "CV available",
    img: EM,
  },
];

const associates = [
  {
    name: "Caroline Eriksson",
    role: "Physiotherapist",
    extra: "CV available",
    img: CE,
  },
  {
    name: "Tania Carrigan",
    role: "Occupational Therapist",
    extra: "CV available",
    img: TC,
  },
  {
    name: "Shu Xiaoyu",
    role: "Physiotherapist",
    extra: "CV available",
    img: XS,
  },
  {
    name: "Victoria Ost",
    role: "Occupational Therapist",
    extra: "CV available",
    img: VO,
  },
  {
    name: "Lesley Clarridge",
    role: "Occupational Therapist",
    extra: "CV available",
    img: LC,
  },
  {
    name: "Lauren Southern",
    role: "Physiotherapist",
    extra: "CV available",
    img: LS,
  },
  {
    name: "Rakesh Shukla",
    role: "Rehab Engineer",
    extra: "CV available",
    img: RS,
  },
  {
    name: "Shelley Chatter-Singh",
    role: "Physiotherapist",
    extra: "CV available",
    img: SCS,
  },
  {
    name: "Laura O'Flaherty",
    role: "Occupational Therapist",
    extra: "CV available",
    img: LOF,
  },
  {
    name: "Jennifer Wright",
    role: "Occupational Therapist",
    extra: "CV available",
    img: JRW,
  },
];

const Client = () => {
  const renderTeamSection = (title, team) => (
    <>
      <ClientSubHeading>{title}</ClientSubHeading>
      <ClientWrapper>
        {team.map((member, index) => (
          <ClientCard key={index}>
            <StyledImage style={{ backgroundImage: `url(${member.img})` }} />
            <ClientH2>{member.name}</ClientH2>
            <ClientP>{member.role}</ClientP>
            <ClientP>{member.extra}</ClientP>
          </ClientCard>
        ))}
      </ClientWrapper>
    </>
  );

  return (
    <ClientContainer id="Client">
      <ClientTopLine>Discover</ClientTopLine>
      <ClientHeader>Our Team</ClientHeader>

      {renderTeamSection("Senior Leadership Team", seniorLeadership)}
      {renderTeamSection("Team Leaders", teamLeaders)}
      {renderTeamSection("Senior Clinical Associates", seniorAssociates)}
      {renderTeamSection("Associates", associates)}
    </ClientContainer>
  );
};

export default Client;
