import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  MarkerClusterer,
  Circle,
  OverlayView,
} from "@react-google-maps/api";
import styled from "styled-components";

export const Container = styled.div`
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--color-lightgrey);
  width: 100vw;
  height: 100vh;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const SearchBoxWrapper = styled.div`
  position: absolute;
  top: 80px; /* Move to the bottom of the screen */
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 997;
  width: 90%; /* Adjust width for better fit on smaller screens */
  max-width: 400px; /* Set max width for larger screens */
`;

export const PostcodeInput = styled.input`
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid var(--color-darkpink);
  font-size: 16px;
  width: 250px;
  outline: none;
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px; /* Add spacing between buttons */
  z-index: 997;
`;

export const SearchButton = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--color-darkpink);
  color: #fff;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-lightergey);
    color: var(--color-darkpink);
  }
`;

const Label = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
`;

type LatLngLiteral = google.maps.LatLngLiteral;
type DirectionsResult = google.maps.DirectionsResult;
type MapOptions = google.maps.MapOptions;

// Adjust label position to be just inside the boundary of the circle
const calculateLabelPosition = (
  center: LatLngLiteral,
  radiusKm: number
): LatLngLiteral => {
  const offsetLat = radiusKm * 0.009 * 0.95; // 0.95 to place the label just inside the boundary
  return { lat: center.lat + offsetLat, lng: center.lng };
};

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

// Locations array
const locations = [
  { name: "Wheel of Health Limited", position: { lat: 50.9097, lng: -1.4044 } },
  { name: "Amanda Jackson", position: { lat: 54.6861, lng: -1.2126 } },
  { name: "Claire Ashton", position: { lat: 53.4098, lng: -2.1576 } },
  { name: "Caroline Eriksson", position: { lat: 51.4452, lng: -0.2065 } },
  { name: "Deborah Bowditch", position: { lat: 51.4816, lng: -3.1791 } },
  { name: "Lizzy McWilliams", position: { lat: 50.8278, lng: -0.1687 } },
  { name: "Emma Wakeling", position: { lat: 50.7156, lng: -3.5309 } },
  { name: "Jo McConnell", position: { lat: 52.192, lng: -2.22 } },
  { name: "Jenny Wright", position: { lat: 54.5689, lng: -1.3187 } },
  { name: "Julian Throssell", position: { lat: 51.5074, lng: -0.1278 } },
  { name: "Lisa Alexander", position: { lat: 50.8225, lng: -0.1372 } },
  { name: "Lesley Claridge", position: { lat: 51.758, lng: -1.2633 } },
  { name: "Louise Lloyd", position: { lat: 52.4862, lng: -1.8904 } },
  { name: "Lauren Osborne", position: { lat: 51.7717, lng: -0.2092 } },
  { name: "Lauren O'Flaherty", position: { lat: 53.4129, lng: -8.2439 } },
  { name: "Lauren Southern", position: { lat: 53.99, lng: -1.5393 } },
  { name: "Rakesh Shukla", position: { lat: 51.3148, lng: -0.5591 } },
  { name: "Shelley Chatter-Singh", position: { lat: 50.9413, lng: 0.132 } },
  { name: "Sam Richardson", position: { lat: 50.9413, lng: 0.132 } },
  { name: "Tania Carrigan", position: { lat: 51.2362, lng: -0.5704 } },
  { name: "Victoria Ost", position: { lat: 52.6369, lng: -1.1398 } },
  { name: "Vicky Thompson", position: { lat: 52.2405, lng: -0.9027 } },
  { name: "Shu Xiaoyu", position: { lat: 51.5112, lng: -0.2983 } },
];

export default function Map() {
  const [postcode, setPostcode] = useState("");
  const [postcodeLocation, setPostcodeLocation] =
    useState<LatLngLiteral | null>(null);
  const [clickedMarker, setClickedMarker] = useState<LatLngLiteral | null>(
    null
  );
  const [directions, setDirections] = useState<DirectionsResult | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const center = useMemo<LatLngLiteral>(
    () => ({ lat: 54.00366, lng: -2.547855 }),
    []
  );
  const [travelDetails, setTravelDetails] = useState<{
    distance: string;
    duration: string;
  } | null>(null);
  const calculateMidpoint = (path) => {
    const totalPoints = path.length;
    const midIndex = Math.floor(totalPoints / 2);
    return path[midIndex];
  };
  const options = useMemo<MapOptions>(
    () => ({
      mapId: "d2f54d8ae91809da",
      disableDefaultUI: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      clickableIcons: true,
    }),
    []
  );

  const customMarkerIcon = {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: "rgba(180,0,78,0.8)",
    fillOpacity: 1,
    strokeWeight: 1,
    strokeColor: "#b4004e",
    scale: 24,
  };

  const createClusterIcon = (color, size) => {
    const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}">
      <circle cx="${size / 2}" cy="${size / 2}" r="${
      size / 2
    }" fill="${color}" />
    </svg>
  `;
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  const clusterStyles = [
    {
      textColor: "white",
      url: createClusterIcon("#b4004e", 40),
      height: 40,
      width: 40,
    },
    {
      textColor: "white",
      url: createClusterIcon("#b4004e", 50),
      height: 50,
      width: 50,
    },
    {
      textColor: "white",
      url: createClusterIcon("#b4004e", 60),
      height: 60,
      width: 60,
    },
  ];

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const geocodePostcode = () => {
    if (!postcode) return;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: postcode }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        const location = results[0].geometry.location;
        const latLng = { lat: location.lat(), lng: location.lng() };
        setPostcodeLocation(latLng);
        mapRef.current?.panTo(latLng);
        mapRef.current?.setZoom(7);
      } else {
        alert("Geocode was not successful for the following reason: " + status);
      }
    });
  };

  const clearPostcode = () => {
    setPostcode("");
    setPostcodeLocation(null);
    setClickedMarker(null);
    setDirections(null);
    if (mapRef.current) {
      mapRef.current.panTo(center);
      mapRef.current.setZoom(6);
    }
  };

  const handleMarkerClick = (location) => {
    setClickedMarker(location.position);
  };

  useEffect(() => {
    if (postcodeLocation && clickedMarker) {
      getDirections(postcodeLocation, clickedMarker);
    }
  }, [postcodeLocation, clickedMarker]);

  const getDirections = (origin: LatLngLiteral, destination: LatLngLiteral) => {
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setDirections(result);

          // Adjust bounds and apply zoom-out logic
          if (mapRef.current) {
            const bounds = new google.maps.LatLngBounds();
            result.routes[0].overview_path.forEach((point) =>
              bounds.extend(point)
            );
            mapRef.current.fitBounds(bounds);

            // Zoom out slightly after fitting bounds
            const currentZoom = mapRef.current.getZoom() || 0;
            mapRef.current.setZoom(4); // Ensure minimum zoom level is 4
          }

          // Save travel details
          const travelInfo = result.routes[0]?.legs[0];
          if (travelInfo) {
            setTravelDetails({
              distance: travelInfo.distance?.text || "N/A",
              duration: travelInfo.duration?.text || "N/A",
            });
          }
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  };

  return (
    <Container>
      <ButtonsWrapper>
        <SearchButton onClick={geocodePostcode}>Search</SearchButton>
        <SearchButton onClick={clearPostcode}>Clear</SearchButton>
      </ButtonsWrapper>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={6}
        options={options}
        onLoad={onLoad}>
        <MarkerClusterer styles={clusterStyles}>
          {(clusterer) => (
            <>
              {locations.map((location, index) => (
                <Marker
                  key={index}
                  position={location.position}
                  title={location.name}
                  // clusterer={clusterer}
                  icon={customMarkerIcon}
                  onClick={() => handleMarkerClick(location)}
                />
              ))}
            </>
          )}
        </MarkerClusterer>

        {postcodeLocation && (
          <>
            <Circle
              center={postcodeLocation}
              radius={60 * 1000 * 1}
              options={{
                fillColor: "#b4004e",
                fillOpacity: 0.2,
                strokeColor: "#b4004e",
              }}
            />
            <OverlayView
              position={calculateLabelPosition(postcodeLocation, 60)}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <Label>Approx 1 Hour</Label>
            </OverlayView>

            <Circle
              center={postcodeLocation}
              radius={60 * 1000 * 3}
              options={{
                fillColor: "#b4004e",
                fillOpacity: 0.2,
                strokeColor: "#b4004e",
              }}
            />
            <OverlayView
              position={calculateLabelPosition(postcodeLocation, 180)}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <Label>Approx 3 Hours</Label>
            </OverlayView>

            <Circle
              center={postcodeLocation}
              radius={60 * 1000 * 5}
              options={{
                fillColor: "#b4004e",
                fillOpacity: 0.2,
                strokeColor: "#b4004e",
              }}
            />
            <OverlayView
              position={calculateLabelPosition(postcodeLocation, 300)}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <Label>Approx 5 Hours</Label>
            </OverlayView>
          </>
        )}

        {clickedMarker && directions && (
          <>
            <DirectionsRenderer directions={directions} />
            {travelDetails && (
              <OverlayView
                position={clickedMarker}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                <Label>
                  Distance: {travelDetails.distance}, Duration:{" "}
                  {travelDetails.duration}
                </Label>
              </OverlayView>
            )}
          </>
        )}
      </GoogleMap>

      <SearchBoxWrapper>
        <PostcodeInput
          type="text"
          placeholder="Enter your postcode"
          value={postcode}
          onChange={(e) => setPostcode(e.target.value)}
        />
      </SearchBoxWrapper>
    </Container>
  );
}
